/** @jsx jsx */
import { jsx } from "theme-ui"
import { Component } from "react"
import PropTypes from "prop-types"

import EcosystemSection from "./ecosystem-section"
import { mediaQueries } from "gatsby-design-tokens/dist/theme-gatsbyjs-org"
import {
  setupScrollersObserver,
  unobserveScrollers,
} from "../../utils/scrollers-observer"

class EcosystemBoard extends Component {
  componentDidMount() {
    setupScrollersObserver()
  }

  componentWillUnmount() {
    unobserveScrollers()
  }

  render() {
    const {
      icons: { plugins: PluginsIcon, starters: StartersIcon },
      starters,
      plugins,
    } = this.props

    return (
      <div
        sx={{
          display: `flex`,
          flexDirection: `column`,
          [mediaQueries.md]: {
            flexDirection: `row`,
            flexWrap: `wrap`,
            height: t =>
              `calc(100vh - (${t.sizes.bannerHeight} + ${t.sizes.headerHeight} + 1px))`,
            pt: 7,
            px: 4,
            pb: 4,
          },
        }}
      >
        <EcosystemSection
          title="插件"
          description="插件是扩展 Gatsby 站点的软件包。插件具有获取内容、转换数据等功能！"
          subTitle="Featured Plugins"
          icon={PluginsIcon}
          links={[
            { label: `插件列表`, to: `/plugins/` },
            {
              label: `创建插件`,
              to: `/docs/creating-plugins/`,
              secondary: true,
            },
            { label: `使用插件`, to: `/docs/plugins/`, secondary: true },
          ]}
          featuredItems={plugins}
        />
        <EcosystemSection
          title="模板"
          description="模板其实是 Gatsby 站点，它们针对不同的使用场景进行了预先配置，为你的项目提供一个良好的开端。"
          subTitle="特色模板"
          icon={StartersIcon}
          links={[
            { label: `模板列表`, to: `/starters/` },
            { label: `使用模板`, to: `/docs/starters/`, secondary: true },
          ]}
          featuredItems={starters}
        />
        <EcosystemSection
          title="外部资源"
          description="有趣的 Gatsby 社区项目和学习资源列表，例如 podcasts 和教程。"
          links={[
            {
              label: `资源列表`,
              to: `/docs/awesome-gatsby-resources/`,
            },
          ]}
        />
      </div>
    )
  }
}

EcosystemBoard.propTypes = {
  icons: PropTypes.object,
  starters: PropTypes.array,
  plugins: PropTypes.array,
}

export default EcosystemBoard
